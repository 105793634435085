<template>
  <div
    class="px-dialog"
    aria-hidden="true"
  >
    <div
      v-if="currentFilter && dialog"
      class="px-dialog-container"
    >
      <a
        href="#"
        class="px-dialog-close"
        aria-hidden="true"
        @click="onToggleCurrentFilter(currentFilter, true)"
      >&times;</a>
      <dd class="px-dropdown-dd px-dropdown-dd-show">
        <ul class="px-dropdown-items">
          <div>
            <div class="px-dropdown-items-wrapper">
              <div
                v-if="currentHeaderConfig?.advanced === 'numeric'"
                class="px-dropdown-numeric-filters"
              >
                <VTextField
                  placeholder="Greater Than (>)"
                  type="number"
                  :disabled="currentFilterData.less !== undefined"
                  :value="currentFilterData.greater"
                  @input="value => updateFiltersAdvanced(currentFilter, value, 'greater')"
                />
                <VTextField
                  placeholder="Less Than (<)"
                  type="number"
                  :disabled="!!currentFilterData.greater"
                  :value="currentFilterData.less"
                  @input="value => updateFiltersAdvanced(currentFilter, value, 'less')"
                />
              </div>

              <div
                v-if="currentHeaderConfig?.advanced === 'date'"
                class="px-dropdown-numeric-filters"
              >
                <CMDatePicker
                  identifier="start"
                  :label="$t('grid_monitor.date_start')"
                  :value="currentFilterData.startDate"
                  @updateDate="value =>
                    updateFiltersAdvanced(
                      currentFilter,
                      formatDate(new Date(value), 'yyyy-mm-dd'),
                      'startDate'
                    )"
                />
                <CMDatePicker
                  identifier="end"
                  :label="$t('grid_monitor.date_end')"
                  :value="currentFilterData.endDate"
                  @updateDate="value =>
                    updateFiltersAdvanced(
                      currentFilter,
                      formatDate(new Date(value), 'yyyy-mm-dd'),
                      'endDate'
                    )"
                />
              </div>

              <div v-if="notAdvancedFilters">
                <VAutocomplete
                  v-model="selectedOptions[currentFilter]"
                  :items="currentFilterOptions"
                  small-chips
                  deletable-chips
                  dense
                  clearable
                  allow-overflow
                  hide-selected
                  multiple
                  placeholder="Select options"
                  @change="values => updateFilters(currentFilter, values)"
                />
              </div>
            </div>
            <li class="px-dropdown-operation">
              <a
                :class="operationItemClass"
                @click="onToggleCurrentFilter(currentFilter)"
              >
                Apply
              </a>
              <a
                v-if="notAdvancedFilters"
                :class="operationItemClass"
                @click="resetFilterOptions(currentFilter)"
              >
                Reset
              </a>
              <a
                v-if="notAdvancedFilters"
                :class="`px-dropdown-operation-item ${allOptionsFilterActive ? 'px-dropdown-filter-disable' : ''}`"
                @click="selectAllFilterOptions(currentFilter)"
              >
                Check all
              </a>
            </li>
          </div>
        </ul>
      </dd>
    </div>
  </div>
</template>

<script>
import formatDate from '@/utils/formatDate'

export default {
  name: 'CMTableFilter',

  components: {
    CMDatePicker: () => import('@/components/Common/CMDatePicker.vue')
  },

  props: {
    headerConfig: {
      type: Object,
      default: () => ({})
    },

    filters: {
      type: Object,
      default: () => ({})
    },

    data: {
      type: Array,
      default: () => ([])
    },

    currentFilter: {
      type: String,
      default: ''
    },

    dialog: {
      type: Boolean,
      default: false
    },
  },

  data: () => ({
    selectedOptions: {},
    updatedFilters: {}
  }),

  computed: {
    headerKeys () {
      const [firstAsset] = this.data ?? []
      return firstAsset ? Object.keys(firstAsset) : []
    },

    currentFilterOptions () {
      return [...new Set(this.data.map(item => item[this.currentFilter]))]
    },

    currentFilterData () {
      return this.filters[this.currentFilter] || {}
    },

    anyOptionFilterActive () {
      return this.currentFilterData
        ? Object.values(this.currentFilterData).some(Boolean)
        : false
    },

    allOptionsFilterActive () {
      const activeOptions = this.currentFilterData
        ? Object
          .values(this.currentFilterData)
          .filter(Boolean)
        : false

      return activeOptions.length === this.currentFilterOptions.length
    },

    notAdvancedFilters () {
      const {
        greater,
        less,
        startDate,
        endDate
      } = this.filters[this.currentFilter] || {}
      
      return !greater && !less && !startDate && !endDate
    },

    operationItemClass () {
      return `px-dropdown-operation-item ${this.anyOptionFilterActive ? '' : 'px-dropdown-filter-disabled'}`
    },

    currentHeaderConfig () {
      return this.headerConfig[this.currentFilter]
    },

    formatDate () {
      return formatDate
    }
  },

  watch: {
    currentFilterData (val, oldVal) {
      if (!val) return false
      if (JSON.stringify(val) === JSON.stringify(oldVal)) return false

      const keys = Object.keys(val)
      const ADVANCED_FILTERS = ['greater', 'less', 'startDate', 'endDate']
      const hasAdvancedFilter = keys.some(key => ADVANCED_FILTERS.includes(key))

      if (hasAdvancedFilter) {
        const { greater, less, startDate, endDate } = this.filters[this.currentFilter]

        this.$emit('onUpdateKey', {
          key: 'filters',
          value: {
            ...this.filters,
            [this.currentFilter]: {
              ...(greater !== undefined && { greater }),
              ...(less !== undefined && { less }),
              ...(startDate !== undefined && { startDate }),
              ...(endDate !== undefined && { endDate })
            }
          }
        })
      }
    }
  },

  methods: {
    updateFilters (currentFilter, values) {
      this.$emit('onUpdateKey', {
        key: 'filters',
        value: {
          ...this.filters,
          [currentFilter]: {
            ...values.reduce((obj, next) => ({
              ...obj,
              [next]: true
            }), {})
          }
        }
      })
    },

    updateFiltersAdvanced (currentFilter, value, key) {
      !value && delete this.filters[currentFilter][key]

      this.$emit('onUpdateKey', {
        key: 'filters',
        value: {
          ...this.filters,
          [currentFilter]: {
            ...this.filters[currentFilter],
            ...(value && { [key]: key.includes('Date')
              ? value
              : parseInt(value, 10)
            })
          }
        }
      })

      this.updatedFilters = {
        ...this.updateFilters,
        [this.currentFilter]: true
      }
    },

    resetFilterOptions (currentFilter) {
      this.selectedOptions[currentFilter] = null

      this.$emit('onUpdateKey', {
        key: 'filters',
        value: {
          ...this.filters,
          [currentFilter]: {}
        }
      })
    },

    selectAllFilterOptions (currentFilter) {
      this.selectedOptions[currentFilter] = this.currentFilterOptions

      this.$emit('onUpdateKey', {
        key: 'filters',
        value: {
          ...this.filters,
          [currentFilter]: this.currentFilterOptions
            .reduce((obj, next) => ({
              ...obj,
              [next]: true
            }), {})
        }
      })
    },

    onToggleCurrentFilter (currentFilter, withReset) {
      if (withReset && this.updatedFilters[this.currentFilter]) {
        this.resetFilterOptions(currentFilter)
      }

      this.$emit('onToggleCurrentFilter', currentFilter)
      this.updatedFilters = {}
    }
  }
}
</script>

<style scoped>
.px-checkbox {
  cursor: pointer;
  font-size: 12px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
}

.px-table-checkbox-wrapper {
  width: 25px;
  display: inline-block;
}

.px-checkbox-input {
  position: absolute;
  left: 0;
  z-index: 1;
  cursor: pointer;
  opacity: 0;
  top: 0;
  bottom: 0;
  right: 0;
}

.px-checkbox-inner {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  width: 16px;
  height: 16px;
  border: 1px solid #abbacc;
  border-radius: 2px;
  background-color: #fff;
  transition: all 0.3s;
}

.px-checkbox-filter {
  display: inline-block;
}

.px-checkbox .px-checkbox-checked .px-checkbox-inner {
  background-color: #f4c020;
  border-color: #f4c020;
}

.px-checkbox-inner:after {
  transform: rotate(45deg) scale(0);
  position: absolute;
  left: 4px;
  top: 2px;
  display: table;
  width: 6px;
  height: 9px;
  border: 2px solid #fff;
  border-top: 0;
  border-left: 0;
  content: " ";
  transition: all 0.1s cubic-bezier(0.71, -0.46, 0.88, 0.6)
}

.px-checkbox .px-checkbox-checked .px-checkbox-inner:after {
  transform: rotate(45deg) scale(1);
  position: absolute;
  display: table;
  border: 2px solid #fff;
  border-top: 0;
  border-left: 0;
  content: " ";
  transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
}

.px-checkbox-checked:after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 2px;
  border: 1px solid #f4c020;
  content: "";
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  visibility: hidden;
}

.px-dropdown {
  display: inline-table;
  margin: 0;
  max-width: 400px;
  width: max-content;
  max-height: 300px;
}

.px-dropdown-dt {
  margin: 0px;
  padding: 0px;
}

.px-dropdown-dt > a {
  width: 90px;
}

.px-dropdown a, .px-dropdown a:visited {
  color: #000;
  text-decoration: none;
  outline: none;
}

.px-table-filter {
  display: inline-block;
  position: relative;
  width: 0;
  height: 16px;
  cursor: pointer;
}

.px-table-filter-icon {
  color: #fff;
  position: absolute;
  top: 4px;
  left: 5px;
}

.px-table-filter-icon-active {
  color: #f4c020;
}

.px-icon {
  display: inline-block;
}

.px-dropdown-dd {
  display: block;
  margin: 0px;
  padding: 0px;
}

.px-dropdown-items {
  min-width: 300px;
  min-height: 50px;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: normal;
  white-space: nowrap;
  top: 2px;
  left: 0px;
  list-style: none;
  border-radius: 2px;
  border: 1px solid #363636;
  padding: 0;
  width: auto;
}

.px-dropdown-items-wrapper {
  max-height: 1000px;
}

.px-dropdown-items-multiple {
  display: table;
  padding: 0 5px;
  width: 100%;
  text-align: left;
}

.px-dropdown-items-multiple > .px-checkbox {
  display: flex !important;
  gap: 10px;
  align-items: center;
}

.px-dropdown-items-multiple span {
  font-size: 14px;
  font-weight: normal;
}

.px-dropdown-items-li {
  white-space: nowrap;
  font-size: 14px;
  height: 32px;
  line-height: 32px;
}

.px-checkbox-content {
  white-space: nowrap;
  cursor: pointer;
  outline: none;
  display: inline-block;
  line-height: 1;
  position: relative;
  vertical-align: text-bottom;
}

.px-checkbox-label {
  margin: 0 6px 0 3px;
  width: 100%;
  color: #ffffffd9 !important;
}

.px-dropdown-operation {
  width: 100%;
  margin-top: 5px;
  font-size: 14px;
  border-top: 1px solid #e8e8e8;
  display: flex;
  gap: 20px;
  padding: 8px 10px 4px 8px;
}

.px-dropdown-operation a {
  text-decoration: none;
  cursor: pointer;
}

.px-dropdown-filter-item {
  color: #ffffffd9;
}

.px-dropdown-filter-disabled {
  color: #999;
  cursor: no-drop !important;
}

.px-dialog-close {
  color: #aaaaaa;
  font-size: 20px;
  text-decoration: none;
  padding: 10px;
  justify-self: end;
}

.px-dialog-close:hover {
  color: #919191;
}

.px-dialog:before {
  content: "";
  display: none;
  background: rgba(0, 0, 0, 0.6);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10
}

.px-dialog-opened:before {
  display: block;
}
.px-dialog-opened .px-dialog-container {
  z-index: 10000;
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  transform: translate(0, 0);
  top: 35%;
}

.px-dialog-container {
  background-color: #363636;
  border: #333333 solid 0px;
  border-radius: 5px;
  margin-left: -200px;
  text-align:center;
  position: fixed;
  left: 50%;
  top: -100%;
  z-index: 11;
  width: 360px;
  box-shadow:0 5px 10px rgba(0,0,0,0.3);
  -webkit-transform: translate(0, -500%);
  -ms-transform: translate(0, -500%);
  transform: translate(0, -500%);
  -webkit-transition: -webkit-transform 0.3s ease-out;
  -moz-transition: -moz-transform 0.3s ease-out;
  -o-transition: -o-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  display: grid;
  padding: 0 15px 10px 15px;
}

.icon-vet-right-arrow:before {
  content: "\e6348";
}

.px-dropdown-numeric-filters {
  display: flex;
  gap: 20px;
  margin-top: 0 !important;
  padding-top: 0 !important;
}

::v-deep .v-autocomplete:not(.v-input--is-focused).v-select--chips input {
  max-height: inherit !important;
}

::v-deep tbody > tr:hover {
  cursor: pointer !important;
  background-color: #896f1f !important;
}

@media screen and (max-width: 1600px) {
  ::v-deep th > span {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    justify-content: center;
    align-items: center;
  }
}

::v-deep .v-input {
  max-height: 200px;
  overflow-y: auto;
  overflow-x: hidden;
}

::v-deep .v-data-footer {
  padding: 6px;
}

::v-deep .v-data-footer__select {
  display: none !important;
}

::v-deep .v-data-footer__pagination {
  margin-left: auto;
}
::v-deep .itemDisabled {
  color: grey;
  background-color: #9e9e9e1c;
}
::v-deep table > tbody > tr.itemDisabled:hover {
  pointer-events: none;
  background-color: #9e9e9e1c !important;
}

::v-deep th,
::v-deep td {
  font-size: 10px !important;
  padding: 0px 4px !important;
}
</style>
